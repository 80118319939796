import React from "react";
import styled from "styled-components";
import { SvgBackground } from "./SvgBackground";
import Link from "next/link";
import { useConfigContext } from "~/providers/ConfigContextProvider";

const LogoContainer = styled.div`
  & a {
    display: block;
    height: 100%;
    width: 100%;

    transition: filter 0.3s;
    &:hover,
    &:active {
      filter: invert(33.33%);
    }

    @media (any-pointer: fine) {
      &:hover {
        filter: invert(33.33%);
      }
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
      height: ${(
        props.theme.space(breakpoint, 5) -
        2 * props.theme.space(breakpoint, 8)
      ).toFixed(3)}px;
      
      width: calc(847.38 * (
        ${(
          props.theme.space(breakpoint, 5) -
          2 * props.theme.space(breakpoint, 8)
        ).toFixed(3)}px
        
         / 226.84));
    `;
    })}
`;

export const Logo = () => {
  const config = useConfigContext();

  return (
    <LogoContainer>
      <Link href={`${config?.baseUrl}/`} passHref>
        <a title="Go to IKON homepage">
          <SvgBackground
            type="logo"
            position="left center"
            width="100%"
            height="100%"
          />
        </a>
      </Link>
    </LogoContainer>
  );
};
