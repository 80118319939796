import React from "react";
import SvgRightArrow from "../svgs/SvgRightArrow";
import SvgClose from "../svgs/SvgClose";
import SvgDownload from "../svgs/SvgDownload";
import Svglogo from "../svgs/Svglogo";
// import SvgLogo60 from "../svgs/SvgLogo60";
import SvgMenu from "../svgs/SvgMenu";
import SvgFacebook from "../svgs/SvgFacebook";
import SvgInstagram from "../svgs/SvgInstagram";
import SvgX from "../svgs/SvgX";
import SvgYouTube from "../svgs/SvgYouTube";
import SvgTikTok from "../svgs/SvgTikTok";
import SvgTriangleDown from "../svgs/SvgTriangleDown";
import SvgPlay from "../svgs/SvgPlay";
import SvgPlus from "../svgs/SvgPlus";
import SvgLinkedIn from "../svgs/SvgLinkedIn";

// use https://jakearchibald.github.io/svgomg/
// use https://yoksel.github.io/url-encoder/

export const SvgBackground = ({
  type,
  width,
  height,
  className = "svg",
  size = "contain",
  position = "center center",
  style,
  svg,
}: {
  type?: string;
  width: string;
  height: string;
  size?: string;
  className?: string;
  position?: string;
  style?: object | undefined;
  svg?: string;
}) => {
  let activeSvg = svg ?? Svglogo;

  switch (type) {
    case "menu":
      activeSvg = SvgMenu;
      break;

    case "close":
      activeSvg = SvgClose;
      break;

    case "play":
      activeSvg = SvgPlay;
      break;

    case "plus":
      activeSvg = SvgPlus;
      break;

    case "arrow":
      activeSvg = SvgRightArrow;
      break;

    case "triangle":
      activeSvg = SvgTriangleDown;
      break;

    case "download":
      activeSvg = SvgDownload;
      break;

    case "facebook":
      activeSvg = SvgFacebook;
      break;

    case "tiktok":
      activeSvg = SvgTikTok;
      break;
    case "linkedin":
      activeSvg = SvgLinkedIn;
      break;

    case "instagram":
      activeSvg = SvgInstagram;
      break;

    case "twitter":
      activeSvg = SvgX;
      break;

    case "youtube":
      activeSvg = SvgYouTube;
      break;
  }

  return (
    <span
      className={className}
      style={{
        ...style,
        display: "block",
        width,
        height,
        backgroundPosition: position,
        backgroundRepeat: "no-repeat",
        backgroundSize: size,
        backgroundImage: `url('data:image/svg+xml,${activeSvg}')`,
      }}
    ></span>
  );
};
